import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';

import adminNotificationStore from '@stores/adminNotification/adminNotificationStore';

import { showSuccessNotify } from '@utils/notify';

interface ComponentState {
  isBusy: boolean;
  visible: boolean;
  message: string;
}

interface ComponentProps {}

class AdminNotificationAdd extends Component<ComponentProps, ComponentState> {
  formRef: FormInstance;

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
      visible: false,
      message: '',
    };
  }

  onOpenDrawer = () => {
    this.setState({ visible: true, message: null });
    setTimeout(() => {
      if (this.formRef) this.formRef.resetFields();
    }, 100);
  };

  componentDidMount(): void {}

  handelClose = () => {
    this.setState({
      message: null,
      visible: false,
    });
  };

  handleSubmit = (data: any) => {
    if (data.message == null || data.message == '') return;
    this.setState({ isBusy: true });
    adminNotificationStore
      .createNotification(data.message)
      .then(() => {
        this.setState({ visible: false });
        showSuccessNotify('Уведомление создано');
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  render() {
    const isBusy = this.state.isBusy;

    return (
      <>
        <Button icon={<PlusOutlined />} onClick={this.onOpenDrawer} size={'small'} type={'link'}>
          Создать
        </Button>
        <AdaptiveDrawer
          title={'Создание'}
          width={'60%'}
          onClose={this.handelClose}
          visible={this.state.visible}
          footer={[
            <Button key={'reject_btn'} onClick={this.handelClose}>
              Отмена
            </Button>,
            <Button loading={isBusy} key={'submit_btn'} type="primary" onClick={() => this.formRef.submit()}>
              Сохранить
            </Button>,
          ]}>
          <Form layout="horizontal" size={'large'} ref={(ref) => (this.formRef = ref)} onFinish={this.handleSubmit}>
            <Form.Item label={'Текст уведомления'} name={'message'} required={true} rules={[{ required: true, message: '' }]}>
              <Input.TextArea maxLength={2000} />
            </Form.Item>
          </Form>
        </AdaptiveDrawer>
      </>
    );
  }
}

export default observer(AdminNotificationAdd);
