import { Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import adminNotificationStore from '@stores/adminNotification/adminNotificationStore';
import { AdminNotificationModel } from '@stores/adminNotification/adminNotificationStoreData';
import designStore from '@stores/designStore/designStore';
import userStore from '@stores/userStore/userStore';

interface Props {}

interface State {
  notifications: AdminNotificationModel[];
}

class AdminNotificationModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      notifications: [],
    };
  }

  componentDidMount() {
    adminNotificationStore.loadData();
  }

  acceptNotification(data: AdminNotificationModel[]) {
    adminNotificationStore.acceptNotification(userStore.userData.userId, data.pop().notificationId);
    this.setState({ notifications: data });
  }

  render() {
    const data = adminNotificationStore.items;
    const isAdmin = userStore.userData.roleCode == 'admin';

    return (
      <Modal
        open={!isAdmin && data.length > 0}
        width={designStore.isMobile ? '100%' : '400px'}
        centered={designStore.isMobile}
        title={'Уведомление'}
        onOk={() => this.acceptNotification(data)}
        okText={'Принять'}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}>
        <br />
        {data[data.length - 1]?.message}
        <br />
      </Modal>
    );
  }
}

export default observer(AdminNotificationModal);
