import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Spin } from 'antd';
import DataGrid, { Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdminNotificationAdd from '@components/fw/AdminNotificationModal/AdminNotificationAdd';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import adminNotificationStore from '@stores/adminNotification/adminNotificationStore';
import { AdminNotificationModel } from '@stores/adminNotification/adminNotificationStoreData';
import designStore from '@stores/designStore/designStore';

interface State {}

class AdminNotificationPage extends Component<RouteComponentProps, State> {
  dataGrid: DataGrid;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    document.title = 'Рассылка';
    adminNotificationStore.loadData();
  }

  handleRemove(item: AdminNotificationModel) {
    adminNotificationStore.delete(item);
  }

  handleChangeState(item: AdminNotificationModel) {
    adminNotificationStore.update(item);
  }

  render() {
    const isBusy = adminNotificationStore.isBusy;
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '5.5rem' : '7rem';
    const height = `calc(100vh - ${padding})`;
    let data = adminNotificationStore.items;

    return (
      <>
        <Spin spinning={isBusy}>
          <AdaptiveCard>
            <div style={{ paddingBottom: 10 }}>
              <AdminNotificationAdd />
            </div>
            <DataGrid
              allowColumnReordering={false}
              dataSource={data}
              remoteOperations={false}
              loadPanel={{ enabled: false }}
              allowColumnResizing={true}
              showBorders={true}
              height={height}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              showColumnLines={true}
              columnMinWidth={30}
              showRowLines={true}
              rowAlternationEnabled={true}
              //onRowDblClick={(data) => this.handleClickRow(data)}
              selection={{ mode: 'single' }}>
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Paging enabled={true} defaultPageSize={25} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              {INDEX_COLUMN}
              <Column allowFixing={true} dataField={'message'} caption={'Текст уведомления'} width={'60%'} dataType={'string'} />
              <Column allowFixing={true} dataField={'createDate'} caption={'Создано'} dataType={'datetime'} width={'20%'} />
              <Column
                allowFixing={true}
                caption={'Активно'}
                alignment={'center'}
                width={'10%'}
                cellRender={(params) => {
                  return (
                    <Checkbox
                      value={params.data.isActive}
                      onChange={() => this.handleChangeState(params.data)}
                      checked={params.data.isActive}
                    />
                  );
                }}
              />
              <Column
                allowFixing={true}
                caption={'Удалить'}
                alignment={'center'}
                width={'10%'}
                cellRender={(params) => {
                  return (
                    <Popconfirm title="Удалить рассылку？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemove(params.data)}>
                      <Button icon={<DeleteOutlined />} title={'Удалить рассылку'} size={'small'} type="text" />
                    </Popconfirm>
                  );
                }}
              />
            </DataGrid>
          </AdaptiveCard>
        </Spin>
      </>
    );
  }
}

export default observer(AdminNotificationPage);
