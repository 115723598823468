import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Popconfirm, Row, Spin } from 'antd';
import DataGrid, { Column, Export, FilterRow, GroupPanel, HeaderFilter, StateStoring } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import ViewTypeRadio from '@components/fw/ViewTypeRadio';

import designStore from '@stores/designStore/designStore';
import { ViewType } from '@stores/dictStore';
import dispatcherStore from '@stores/dispatcherStore/dispatcherStore';
import { DistributionModel, emptyDistribution } from '@stores/distributionStore/dictributionData';
import distributionStore from '@stores/distributionStore/distributionStore';
import userStore from '@stores/userStore/userStore';

import { getTitle } from '@utils/roleAccess';

import DistributionEditor from './DistributionEditor';

interface DistributionPageProps extends RouteComponentProps {
  type: 'billing' | 'message';
}

interface DistributionPageState {
  viewType: ViewType;
}

export const WEAK_DAYS: { [key: number]: string } = {
  1: 'понедельникам',
  2: 'вторникам',
  3: 'средам',
  4: 'четвергам',
  5: 'пятницам',
  6: 'субботам',
  0: 'воскресеньям',
};

class DistributionPage extends Component<DistributionPageProps, DistributionPageState> {
  dataGrid: DataGrid;
  constructor(props: DistributionPageProps) {
    super(props);

    this.state = {
      viewType: 'table',
    };
  }

  componentDidMount() {
    distributionStore.loadData();
    dispatcherStore.getReportList();
  }

  handleRemove = (item: DistributionModel) => {
    distributionStore.delete(item);
  };

  cardVew = () => {
    const data = distributionStore.items;
    const userId = userStore.userData.userId;
    if (!data.length) return <Empty />;
    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {data.map((item) => {
          return (
            <Col xs={24} sm={24} md={12} xl={8} xxl={6} key={item.distributionId}>
              <AdaptiveCard
                hoverable
                title={item.customReportName}
                extra={
                  item.userId != null && item.userId == userId ? (
                    <>
                      <DistributionEditor data={item} />
                      <Popconfirm title="Удалить рассылку？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemove(item)}>
                        <Button icon={<DeleteOutlined />} title={'Удалить рассылку'} size={'small'} type="text" />
                      </Popconfirm>
                    </>
                  ) : (
                    <></>
                  )
                }>
                {item.filterName ? (
                  <p>
                    <b>Фильтр:</b> {item.filterName}
                  </p>
                ) : null}
                <p>
                  <b>Время рассылки:</b> {item.days.length == 7 ? 'каждый день' : 'по ' + item.days.map((d) => WEAK_DAYS[d]).join(', ')} в{' '}
                  {item.times.join(', ')}
                </p>
                <p>
                  <b>Адреса:</b> {item.emails.join(', ')}
                </p>
                <p>
                  <b>Создан:</b> {moment(item.createDate).fromNow()}
                </p>
              </AdaptiveCard>
            </Col>
          );
        })}
      </Row>
    );
  };

  tableView = () => {
    const data = distributionStore.items;
    const userId = userStore.userData.userId;

    return (
      <DataGrid
        allowColumnReordering={true}
        dataSource={data}
        height={'100%'}
        width={'100%'}
        loadPanel={{ enabled: false }}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        showColumnLines={true}
        showBorders={true}
        columnMinWidth={30}
        showRowLines={true}
        rowAlternationEnabled={true}>
        <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'distributionPage'} />
        <FilterRow visible={true} />
        <Export enabled={true} excelWrapTextEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Column
          allowFixing={true}
          cellRender={(params) => {
            if (params.data.userId != null && params.data.userId == userId) {
              return (
                <span>
                  <DistributionEditor data={params.data} />
                  <Popconfirm title="Удалить рассылку？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemove(params.data)}>
                    <Button icon={<DeleteOutlined />} title={'Удалить рассылку'} size={'small'} type="text" />
                  </Popconfirm>
                </span>
              );
            }
          }}
        />
        <Column allowFixing={true} caption={'Компания'} dataField={'companyName'} defaultGroupIndex={0} />
        <Column allowFixing={true} caption={'Пользователь'} dataField={'userName'} dataType={'string'} />
        <Column allowFixing={true} caption={'Отчет'} dataField={'customReportName'} dataType={'string'} />
        <Column allowFixing={true} caption={'Фильтр'} dataField={'filterName'} dataType={'string'} />
        <Column
          allowFixing={true}
          caption={'Адреса'}
          dataField={'emails'}
          dataType={'string'}
          allowFiltering={true}
          allowSearch={true}
          calculateFilterExpression={(dxValue, selectedFilterOperations, target) => {
            return ['emails', 'contains', dxValue];
          }}
          allowHeaderFiltering={false}
          cellRender={(params) => {
            return <>{Array.isArray(params.value) ? params.value.map((e) => <p key={e}>{e}</p>) : params.value}</>;
          }}
        />
        <Column
          allowFixing={true}
          allowSearch={false}
          allowGrouping={false}
          allowFiltering={false}
          caption={'Дни'}
          dataField={'days'}
          customizeText={(params) =>
            params.value.length == 7
              ? 'каждый день'
              : 'по ' + (Array.isArray(params.value) ? params.value.map((d) => WEAK_DAYS[d]).join(', ') : params.value)
          }
        />
        <Column
          allowFixing={true}
          caption={'Время'}
          dataField={'times'}
          dataType={'string'}
          allowHeaderFiltering={false}
          customizeText={(params) => (Array.isArray(params.value) ? params.value.join(', ') : params.value)}
          calculateFilterExpression={(dxValue, selectedFilterOperations, target) => {
            return ['times', 'contains', dxValue];
          }}
        />
        <Column allowFixing={true} caption={'Создан'} dataField={'createDate'} dataType={'date'} />
      </DataGrid>
    );
  };

  handleViewTypeChanged = (value: ViewType) => {
    this.setState({ viewType: value });
  };

  render() {
    const isBusy = distributionStore.isBusy;
    const viewType = this.state.viewType;
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '5.5rem' : '7rem';
    const height = `calc(100vh - ${padding})`;
    document.title = getTitle(this.props.match.path.slice(1));
    return (
      <div>
        <AdaptivePageHeader
          title={'Рассылки'}
          subTitle={!isMobile && <ViewTypeRadio key={'table_board'} onChange={this.handleViewTypeChanged} type={viewType} size={'small'} />}
          style={{ background: 'white' }}
          extra={<DistributionEditor data={emptyDistribution} />}
        />
        <Spin spinning={isBusy}>
          <AdaptiveCard>
            <ScrolledContainer className={'scrolledContainer'} style={{ height: height, background: 'white' }}>
              {viewType === 'table' ? this.tableView() : this.cardVew()}
            </ScrolledContainer>
          </AdaptiveCard>
        </Spin>
      </div>
    );
  }
}

export default observer(DistributionPage);
