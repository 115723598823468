import { AdminNotificationModel } from '@stores/adminNotification/adminNotificationStoreData';

import HttpClient from '@utils/httpClient';

const service = {
  async createNotification(message: string): Promise<void> {
    await HttpClient.post(`/api/adminNotification/create`, { message });
  },

  async removeNotification(notificationId: number): Promise<void> {
    await HttpClient.delete(`/api/adminNotification/remove/${notificationId}`);
  },

  async getNotification(notificationId: number): Promise<AdminNotificationModel> {
    return (await HttpClient.get(`/api/adminNotification/${notificationId}`)).data;
  },

  async getNotifications(): Promise<AdminNotificationModel[]> {
    return (await HttpClient.get(`/api/adminNotification/list`)).data;
  },

  async updateStatus(data: any): Promise<void> {
    await HttpClient.post(`/api/adminNotification/update-status`, data);
  },

  async getNotificationsByUser(userId: string): Promise<AdminNotificationModel[]> {
    return (await HttpClient.get(`/api/adminNotification/${userId}/list`)).data;
  },

  async acceptNotification(userId: string, notificationId: number): Promise<void> {
    await HttpClient.post(`/api/adminNotification/${userId}/accept/${notificationId}`);
  },
};

export default service;
