import * as signalR from '@microsoft/signalr';
import { IRetryPolicy, RetryContext } from '@microsoft/signalr';
import { action, makeObservable, observable } from 'mobx';

import service from '@stores/adminNotification/adminNotificationStore.service';
import { AdminNotificationModel } from '@stores/adminNotification/adminNotificationStoreData';
import userStore from '@stores/userStore/userStore';

import { getAuthority } from '@utils/authority';

import config from '../../config';

const { urlApi } = config;

class AdminNotificationStore {
  @observable isBusy: boolean = false;
  @observable items: AdminNotificationModel[] = [];

  connection: signalR.HubConnection;

  constructor() {
    makeObservable(this);
  }

  @action
  async loadData() {
    this.isBusy = true;
    try {
      const isAdmin = userStore.userData.roleCode == 'admin';
      if (isAdmin) this.items = await service.getNotifications();
      else this.items = await service.getNotificationsByUser(userStore.userData.userId);
    } finally {
      this.isBusy = false;
    }
  }

  @action
  async update(data: AdminNotificationModel) {
    await service.updateStatus(data);
    await this.loadData();
  }

  @action
  async delete(data: AdminNotificationModel) {
    if (!data.notificationId) return;
    await service.removeNotification(data.notificationId);
    await this.loadData();
  }

  @action
  async acceptNotification(userId: string, notificationId: number) {
    await service.acceptNotification(userId, notificationId);
  }

  @action
  async createNotification(message: string) {
    await service.createNotification(message);
  }

  async connect() {
    const retryPolicy: IRetryPolicy = {
      nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
        return 5000;
      },
    };
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${urlApi}/adminNotification`, { accessTokenFactory: () => getAuthority().access_token })
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect(retryPolicy)
      .build();

    await this.start();

    this.connection.on('Publish', () => {
      this.loadData();
    });
  }

  async start() {
    try {
      if (this.connection) {
        await this.connection.stop();
      }
      await this.connection.start();
    } catch (err) {
      setTimeout(() => this.start(), 5000);
    }
  }
}

export default new AdminNotificationStore();
